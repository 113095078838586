import React from "react";
import heroImage from "../../Assets/Images/hero.png";
import { Link } from "react-router-dom";

function Home(props) {
  return (
    <div className="content">
      <div className="intro-y news p-5 box mt-8">
        <div className="intro-y mt-0 mb-6">
          <div className="news__preview image-fit">
            <img alt="hero" className="rounded-md" src={heroImage} />
          </div>
        </div>
        <div className="intro-y text-justify leading-relaxed">
          <h2 className="intro-y font-medium text-xl sm:text-2xl mb-2">
            Set your appointment online in just a few minutes.
          </h2>
          {/* <div className="mb-2">Experience quicker visits at a time that's right for you. For your convenience,
            you can now set an appointment at your preferred Branches to personally speak with
            a ILPI representative about the following:</div>
          <div className="flex items-center text-gray-700 mt-0">
            <input type="checkbox" checked disabled className="input border mr-2" />
            <label className="cursor-pointer select-none">Understanding your bill</label>
          </div>
          <div className="flex items-center text-gray-700 mt-2">
            <input type="checkbox" checked disabled className="input border mr-2" />
            <label className="cursor-pointer select-none">Settle service application charges</label>
          </div>
          <div className="flex items-center text-gray-700 mt-2 mb-4">
            <input type="checkbox" checked disabled className="input border mr-2" />
            <label className="cursor-pointer select-none">Report concerns about your account</label>
          </div> */}
          {/* <div className="mb-4">For everyone's safety we allow a limited number of people inside our Branches at one time,
            so it's best to book an appointment.</div> */}
          <div className="mb-4">
            For your convenience, you can now schedule an appointment to meet
            with us to discuss an existing account, open a new account, or talk
            about what concerns you with regards to our services. You can choose
            a day and time that works best for your busy schedule.
          </div>
          <Link
            to="/set-appointment"
            className="button w-24 mr-0 mb-0 bg-theme-1 text-white"
            style={{ width: 150 }}
          >
            Set Appointment
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
