import React from "react";
import FeatherIcon from "feather-icons-react";

function Card({ title, subtitle, handleClick, icon }) {
  return (
    <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y">
      <div
        className="report-box zoom-in ml-5 mr-5 mt-5 mb-5"
        onClick={() => handleClick(title)}
      >
        <div className="box p-5">
          <div className="flex">
            <FeatherIcon
              icon={icon}
              fill={"lightBlue"}
              className="report-box__icon text-theme-10"
            />
          </div>
          <div className="text-2xl font-bold leading-8 mt-6">{title}</div>
          <div className="text-base text-gray-600 mt-1">{subtitle}</div>
        </div>
      </div>
    </div>
  );
}

export default Card;
