import React from "react";
import FeatherIcon from "feather-icons-react";
import { useLocation, Link } from "react-router-dom";

function Navbar(props) {
  const location = useLocation();

  return (
    <nav className="top-nav">
      <ul>
        <li>
          {location.pathname === "/" ? (
            <Link to="/" className="top-menu top-menu--active">
              <div className="top-menu__icon">
                {" "}
                <FeatherIcon icon="home" size={20} />{" "}
              </div>
              <div className="top-menu__title"> Home </div>
            </Link>
          ) : (
            <Link to="/" className="top-menu top-menu">
              <div className="top-menu__icon">
                {" "}
                <FeatherIcon icon="home" size={20} />{" "}
              </div>
              <div className="top-menu__title"> Home </div>
            </Link>
          )}
        </li>
        <li>
          {location.pathname === "/set-appointment" ? (
            <Link to="/set-appointment" className="top-menu top-menu--active">
              <div className="top-menu__icon">
                {" "}
                <FeatherIcon icon="calendar" size={20} />{" "}
              </div>
              <div className="top-menu__title"> Set Appointment </div>
            </Link>
          ) : (
            <Link to="/set-appointment" className="top-menu top-menu">
              <div className="top-menu__icon">
                {" "}
                <FeatherIcon icon="calendar" size={20} />{" "}
              </div>
              <div className="top-menu__title"> Set Appointment </div>
            </Link>
          )}
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
