import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "../src/Components/Header/Header";
import Navbar from "../src/Components/Navbar/Navbar";
import NotFound from "../src/Pages/NotFound/NotFound";
import Home from "./Pages/Home/Home";
import SetAppointment from "./Pages/SetAppointment/SetAppointment";
import Confirmation from "./Pages/Confirmation/Confirmation";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Navbar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/set-appointment">
            <SetAppointment />
          </Route>
          <Route path="/confirmation">
            <Confirmation />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
