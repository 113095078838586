import React from "react";
// import logo from '../../Assets/Images/logo.svg';
import logo from "../../Assets/Images/logo.png";

function Header(props) {
  return (
    <div className="border-b border-theme-24 -mt-10 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 pt-3 md:pt-0 mb-10">
      <div className="top-bar-boxed flex items-center">
        <a href="/" className="-intro-x hidden md:flex">
          <img alt="logo" className="w-12" src={logo} />
          <span className="text-white text-lg ml-3 mt-3">
            <span className="font-medium">Customer Online Appointment</span>{" "}
          </span>
        </a>
      </div>
    </div>
  );
}

export default Header;
