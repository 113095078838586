import React from "react";

function NotFound(props) {
  return (
    <div className="content">
      <h2 className="intro-y text-6xl font-medium mt-10 text-center">404</h2>
      <div className="intro-y text-2xl font-medium text-center leading-relaxed">
        <div className="mb-2">Page Not Found</div>
      </div>
    </div>
  );
}

export default NotFound;
