import React, { useState, useEffect } from "react";
import Card from "../../../../Components/Card/Card";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import useGetTimeSlots from "../../../../Api/useGetTimeSlots";
import { useHistory } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Input from "react-phone-number-input/input";
import {Api} from "../../../../Api/api";

const localizer = momentLocalizer(moment);

function Book({
  services,
  concerns,
  serviceName,
  concernName,
  step,
  handleChooseServiceName,
  handleChooseConcernName,
}) {
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [appointmentStart, setAppointmentStart] = useState("");
  const [appointmentEnd, setAppointmentEnd] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedSlotId, setSelectedSlotId] = useState();
  const [moreDetails, setMoreDetails] = useState("");
  const [error, setError] = useState(null);
  const [verificationCode] = useState("");
  const events = useGetTimeSlots();
  var today = new Date();


  useEffect(()=>{
    if (events.length === 0){
      setError("No available timeslots for all dates as of now.")
    }
    else{
      setError(null)
    }
  },[events])

  const handleChangeCheckbox = () => {
    setIsChecked(!isChecked);
  };
  const handleSelectSlot = (slot) => {
    if (!isSubmitted) {
      setSelectedSlotId(slot["id"]);
      const appStart = moment(slot["start"]).format("YYYY-MM-DDTHH:mm:ss");
      const appEnd = moment(slot["end"]).format("YYYY-MM-DDTHH:mm:ss");
      setAppointmentStart(appStart);
      setAppointmentEnd(appEnd);

      const start = moment(slot["start"]).format("ddd DD MMM YYYY hh:mm A");
      const end = moment(slot["end"]).format("hh:mm A");
      slot = `${start} - ${end}`;
      setSelectedSlot(slot);
    }
  };
  const handleChangeAccountNumber = (e) => {
    setAccountNumber(e.target.value);
  };
  const handleChangeFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const handleChangeLastName = (e) => {
    setLastName(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  // const handleChangePhoneNumber = (e) => {
  //   setPhoneNumber(e.target.value)
  // }
  const handleChangeMoreDetails = (e) => {
    setMoreDetails(e.target.value);
  };
  const handleCloseErrorMessage = () => {
    setError(null);
  };
  const handleSubmit = (e) => {
    setError(null);
    e.preventDefault();
    setIsSubmitted(true);
    const postBody = {
      verification_code: verificationCode,
      service: serviceName,
      concern: concernName,
      account_number: accountNumber,
      appointment_start: appointmentStart,
      appointment_end: appointmentEnd,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email: email,
      more_details: moreDetails,
    };
    fetch(`${Api.host}/appointments-api/book-appointment/send/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(postBody),
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.email) {
          setIsSubmitted(false);
          setError(res.email);
        }
        if (res.phone_number) {
          setIsSubmitted(false);
          setError(res.phone_number);
        }
        if (res.error) {
          setIsSubmitted(false);
          setError(res.error);
        }
        if (res.success) {
          history.push({
            pathname: "/confirmation",
            state: {
              selectedSlotId: selectedSlotId,
              firstName: firstName,
              lastName: lastName,
              phoneNumber: phoneNumber,
            },
          });
        }
      });
    setIsSubmitted(false);
  };

  return (
    <>
      {step === 1
        ? services.map((service) => (
            <Card
              key={service.id}
              title={service.name}
              icon={"file"}
              subtitle={<br />}
              handleClick={handleChooseServiceName}
            />
          ))
        : null}
      {step === 2
        ? concerns.map((concern) =>
            concern.concerns.map((c) => (
              <Card
                key={c.id}
                title={c.name}
                icon={"file"}
                subtitle={<br />}
                handleClick={handleChooseConcernName}
              />
            ))
          )
        : null}
      {step === 3 ? (
        <>
          <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y">
            <div className="box p-5">
              <Calendar
                localizer={localizer}
                events={events}
                defaultView={"week"}
                defaultDate={events.length === 0 ? today : events[events.length-1].start}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 735 }}
                onSelectEvent={(e) => handleSelectSlot(e)}
              />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y">
            {error !== null ? (
              <div className="rounded-md flex items-center px-5 py-4 mb-2 bg-theme-6 text-white">
                {error}
                <FeatherIcon
                  icon="x"
                  className="w-4 h-4 ml-auto"
                  onClick={handleCloseErrorMessage}
                />
              </div>
            ) : null}
            <div className="box p-5">
              <h2>
                <strong>Complete all the fields</strong>
              </h2>
              <div className="p-5" id="basic-datepicker">
                <form
                  className="validate-form"
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <div className="mt-3">
                    <label className="flex flex-col sm:flex-row">
                      Account Number
                    </label>
                    <input
                      type="text"
                      className="input w-full border mt-2"
                      placeholder="B1234"
                      disabled={isSubmitted}
                      value={accountNumber}
                      onChange={(e) => handleChangeAccountNumber(e)}
                    />
                  </div>
                  <div className="mt-3">
                    <label className="flex flex-col sm:flex-row">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="input w-full border mt-2"
                      placeholder="John"
                      required
                      disabled={isSubmitted}
                      value={firstName}
                      onChange={(e) => handleChangeFirstName(e)}
                    />
                  </div>
                  <div className="mt-3">
                    <label className="flex flex-col sm:flex-row">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="input w-full border mt-2"
                      placeholder="Doe"
                      required
                      disabled={isSubmitted}
                      value={lastName}
                      onChange={(e) => handleChangeLastName(e)}
                    />
                  </div>
                  <div className="mt-3">
                    <label className="flex flex-col sm:flex-row"> Email </label>
                    <input
                      type="email"
                      className="input w-full border mt-2"
                      placeholder="example@gmail.com"
                      disabled={isSubmitted}
                      value={email}
                      onChange={(e) => handleChangeEmail(e)}
                    />
                  </div>
                  <div className="mt-3">
                    <label className="flex flex-col sm:flex-row">
                      {" "}
                      Phone Number
                    </label>
                    {/* <input
                        type="text"
                        className="input w-full border mt-2"
                        placeholder="09123456789"
                        minLength="11"
                        maxLength="11"
                        disabled={isSubmitted}
                        required
                        value={phoneNumber}
                        onChange={(e) => handleChangePhoneNumber(e)} /> */}
                    <Input
                      type="text"
                      className="input w-full border mt-2"
                      country="PH"
                      placeholder="09561234567"
                      disabled={isSubmitted}
                      value={phoneNumber}
                      minLength={13}
                      maxLength={13}
                      required
                      onChange={(e) => setPhoneNumber(e)}
                    />
                  </div>
                  <div className="mt-3">
                    <label className="flex flex-col sm:flex-row">
                      Time Slot
                    </label>
                    <input
                      type="text"
                      className="input w-full border mt-2"
                      disabled={isSubmitted}
                      required
                      readonly
                      value={selectedSlot}
                      onChange={() => console.log()}
                    />
                  </div>
                  <div className="mt-3">
                    <label className="flex flex-col sm:flex-row">
                      {" "}
                      Tell us more about your concern
                    </label>
                    <textarea
                      className="input w-full border mt-2"
                      placeholder="Tell us more here..."
                      maxLength="200"
                      value={moreDetails}
                      disabled={isSubmitted}
                      onChange={(e) => handleChangeMoreDetails(e)}
                    />
                  </div>
                  <div className="flex items-center text-gray-700 mt-2">
                    <input
                      type="checkbox"
                      required
                      disabled={isSubmitted}
                      className="input border mr-2"
                      id="tc"
                      onChange={handleChangeCheckbox}
                    />
                    <label htmlFor="tc" className="cursor-pointer select-none">
                      I agree to the{" "}
                      <a href="https://www.iliganlight.com/privacy-policy.php">
                        <strong>Terms and Conditions</strong>
                      </a>
                    </label>
                  </div>
                  {!isChecked ? (
                    <button
                      disabled
                      className="button bg-gray-200 text-gray-600 mt-5"
                    >
                      Confirm Appointment
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="button bg-theme-1 text-white mt-5"
                    >
                      {isSubmitted ? "Submitting..." : "Confirm Appointment"}
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default Book;
