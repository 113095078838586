import React, { useEffect, useState } from "react";
import Card from "../../Components/Card/Card";
import Book from "./Components/Book/Book";
import Reschedule from "./Components/Reschedule/Reschedule";
import Cancel from "./Components/Cancel/Cancel";

const appointmentTypes = [
  {
    id: 1,
    title: "BOOK",
    subtitle: "an appointment",
  },
  {
    id: 2,
    title: "RESCHEDULE",
    subtitle: "an appointment",
  },
  {
    id: 3,
    title: "CANCEL",
    subtitle: "an appointment",
  },
];

const services = [
  {
    id: 1,
    name: "Service Application",
    concerns: [
      {
        id: 1,
        name: "New Connection",
      },
      {
        id: 2,
        name: "Account Reconnection",
      },
      {
        id: 3,
        name: "Assumption of Account",
      },
      {
        id: 4,
        name: "Temporary Connection",
      },
    ],
  },
  {
    id: 2,
    name: "Bill or Payment Concerns",
    concerns: [
      {
        id: 1,
        name: "Partial Payment",
      },
      {
        id: 2,
        name: "Bill Query",
      },
      {
        id: 3,
        name: "Payment Extension",
      },
    ],
  },
  {
    id: 3,
    name: "Requests",
    concerns: [
      {
        id: 1,
        name: "Meter Reading Re-check",
      },
      {
        id: 2,
        name: "Pole Maintenance",
      },
      {
        id: 3,
        name: "Vegetation Clearing",
      },
      {
        id: 4,
        name: "Meter Testing",
      },
      {
        id: 5,
        name: "Other Request",
      },
    ],
  },
  {
    id: 4,
    name: "Other Concerns",
    concerns: [
      {
        id: 1,
        name: "Queries",
      },
      {
        id: 2,
        name: "Others",
      },
    ],
  },
];

function SetAppointment(props) {
  const [bookingType, setBookingType] = useState(null);
  const [step, setStep] = useState(0);
  const [serviceName, setServiceName] = useState(null);
  const [concernName, setConcernName] = useState(null);
  const [concerns, setConcerns] = useState([]);

  const handleChooseAppointmentType = (title) => {
    setBookingType(title);
    setStep(step + 1);
  };

  const handleChooseServiceName = (serviceName) => {
    setServiceName(serviceName);
    setStep(step + 1);
  };

  const handleChooseConcernName = (concernName) => {
    setConcernName(concernName);
    setStep(step + 1);
  };

  const handleBack = () => {
    if (step <= 1) {
      setBookingType(null);
      setServiceName(null);
      setConcernName(null);
      setStep(0);
    } else {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    setConcerns(services.filter((s) => s.name === serviceName));
  }, [serviceName]);
  return (
    <div className="content">
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
          <div className="col-span-12 mt-8">
            {step > 0 ? (
              <div className="intro-y flex items-center h-10">
                <div className="intro-y flex items-center mt-0">
                  <button
                    onClick={handleBack}
                    className="button w-24 mr-1 mb-2 bg-theme-6 text-white"
                  >
                    Back
                  </button>
                </div>
              </div>
            ) : null}
            <div className="grid grid-cols-12 gap-6 mt-5">
              {bookingType === null
                ? appointmentTypes.map((appointmentType) => (
                    <Card
                      key={appointmentType.id}
                      title={appointmentType.title}
                      subtitle={appointmentType.subtitle}
                      icon={"calendar"}
                      handleClick={handleChooseAppointmentType}
                    />
                  ))
                : null}
              {bookingType === "BOOK" ? (
                <Book
                  services={services}
                  concerns={concerns}
                  step={step}
                  serviceName={serviceName}
                  concernName={concernName}
                  handleChooseServiceName={handleChooseServiceName}
                  handleChooseConcernName={handleChooseConcernName}
                />
              ) : null}
              {bookingType === "RESCHEDULE" ? <Reschedule /> : null}
              {bookingType === "CANCEL" ? <Cancel /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetAppointment;
