import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {Api} from "../../Api/api";

function Confirmation(props) {
  const location = useLocation();
  const [isVerified, setIsVerified] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    setVerificationCode(e.target.value.toUpperCase());
  };

  const handleResend = () => {
    setError(null);
    setSuccess(null);
    setIsResend(true);
    setTimeout(() => {
      if (typeof location.state === "undefined") {
        setSuccess(null);
        setError("Set an appointment first.");
        setIsResend(false);
      } else {
        fetch(`${Api.host}/appointments-api/resend/`, {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            verification_code: "",
            first_name: location.state.firstName,
            last_name: location.state.lastName,
            phone_number: location.state.phoneNumber,
          }),
        })
          .then((res) => {
            if (res.status !== 200) {
              throw Error("Could not get data from that resource.");
            }
            return res.json();
          })
          .then((data) => {
            if (data.success) {
              setError(null);
              setSuccess(data.success);
            }
            if (data.error) {
              setSuccess(null);
              setError(data.error);
            }
          });
        setIsResend(false);
      }
    }, 3000);
  };

  const handleSubmit = (e) => {
    setError(null);
    setSuccess(null);
    e.preventDefault();
    setIsSubmitted(true);
    setTimeout(() => {
      fetch(
        `${Api.host}/appointments-api/book-appointment/${verificationCode}/`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            status: "CONFIRMED",
          }),
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          if (res.error) {
            setIsSubmitted(false);
            setError(res.error);
          }
          if (res.success) {
            fetch(
              `${Api.host}/appointments-api/timeslots/${location.state.selectedSlotId}/`,
              {
                method: "PATCH",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  is_available: false,
                }),
              }
            )
              .then((res) => {
                if (res.status !== 200) {
                  throw Error("Could not get data from that resource.");
                }
                return res.json();
              })
              .then((data) => {
                setIsVerified(true);
              });
          }
        });
      setIsSubmitted(false);
    }, 3000);
  };

  return (
    <>
      {isVerified ? (
        <div className="content">
          <h2 className="intro-y text-6xl font-medium mt-10 text-center">
            Thank you for booking with us.
          </h2>
          <div className="intro-y text-2xl font-medium text-center leading-relaxed">
            <div className="mb-4">
              We've got your booking appointment, we will send you an email
              confirming that you <strong>booked</strong> an appointment. Kindly
              check your inbox.
            </div>
            <Link
              to="/"
              className="button w-24 mr-0 mb-0  bg-theme-1 text-white"
              style={{ width: 150 }}
            >
              Back to Home
            </Link>
          </div>
        </div>
      ) : (
        <div className="content">
          <h2 className="intro-y text-3xl font-medium mt-10 text-center">
            Enter the verification code that was sent to your phone.
          </h2>
          <form className="validate-form" onSubmit={(e) => handleSubmit(e)}>
            <div className="mt-3 text-center">
              <input
                type="text"
                className="input w-full border mt-2 mr-2"
                minLength={8}
                maxLength={16}
                style={{ width: 400 }}
                disabled={isSubmitted || isResend}
                required
                value={verificationCode}
                onChange={(e) => handleChange(e)}
              />
              <button
                disabled={isSubmitted || isResend}
                type="submit"
                className="button bg-theme-1 text-white mt-5 mr-2"
              >
                {isSubmitted ? "Verifying..." : "Verify"}
              </button>
              <button
                onClick={handleResend}
                disabled={isSubmitted || isResend}
                type="button"
                className="button bg-theme-7 text-white mt-5 mr-2"
              >
                {isResend ? "Resending..." : "Resend Code"}
              </button>
              {error ? <div className="text-theme-6 mt-2">{error}</div> : null}
              {success ? (
                <div className="text-theme-9 mt-2">{success}</div>
              ) : null}
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default Confirmation;
