import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import useGetTimeSlots from "../../../../Api/useGetTimeSlots";
import FeatherIcon from "feather-icons-react";
import {Api} from "../../../../Api/api";

const localizer = momentLocalizer(moment);

function Reschedule(props) {
  const [error, setError] = useState(null);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [success, setSuccess] = useState(null);
  const [verified, setVerified] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [changed, setChanged] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const events = useGetTimeSlots();
  const [appointmentStart, setAppointmentStart] = useState("");
  const [appointmentEnd, setAppointmentEnd] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedSlotId, setSelectedSlotId] = useState();
  const [isChecked, setIsChecked] = useState(true);
  var today = new Date();

  useEffect(()=>{
    if (events.length === 0){
      setError("No available timeslots for all dates as of now.")
    }
    else{
      setError(null)
    }
  },[events])

  const handleSelectSlot = (slot) => {
    if (!isSubmitted) {
      setSelectedSlotId(slot["id"]);
      const appStart = moment(slot["start"]).format("YYYY-MM-DDTHH:mm:ss");
      const appEnd = moment(slot["end"]).format("YYYY-MM-DDTHH:mm:ss");
      setAppointmentStart(appStart);
      setAppointmentEnd(appEnd);

      const start = moment(slot["start"]).format("ddd DD MMM YYYY hh:mm A");
      const end = moment(slot["end"]).format("hh:mm A");
      slot = `${start} - ${end}`;
      setSelectedSlot(slot);
    }
  };
  const handleCloseErrorMessage = () => {
    setError(null);
  };

  const handleResend = () => {
    setError(null);
    setSuccess(null);
    setIsResend(true);
    fetch(`${Api.host}/appointments-api/resend/`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        verification_code: "",
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw Error("Could not get data from that resource.");
        }
        return response.json();
      })
      .then((res) => {
        if (res.success) {
          setError(null);
          setSuccess(res.success);
        }
        if (res.error) {
          setSuccess(null);
          setError(res.error);
        }
      });
    setIsResend(false);
  };

  const handleSubmit = (e) => {
    setError(null);
    setSuccess(null);
    e.preventDefault();
    setIsSubmitted(true);
    fetch(
      `${Api.host}/appointments-api/reschedule-appointment/${referenceNumber}/`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.error) {
          setError(res.error);
        } else {
          setPhoneNumber(res.phone_number);
          setFirstName(res.first_name);
          setLastName(res.last_name);
          setVerified(true);
        }
      });
    setIsSubmitted(false);
  };

  const handleSubmit2 = (e) => {
    setError(null);
    setSuccess(null);
    e.preventDefault();
    setIsSubmitted(true);
    fetch(
      `${Api.host}/appointments-api/reschedule-appointment/${verificationCode}/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.error) {
          setError(res.error);
        } else {
          setConfirmed(true);
        }
      });
    setIsSubmitted(false);
  };

  const handleSubmit3 = (e) => {
    setError(null);
    e.preventDefault();
    setIsSubmitted(true);
    const postBody = {
      selectedSlotId,
      referenceNumber,
    };
    fetch(
      `${Api.host}/appointments-api/reschedule-appointment/success/`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postBody),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.error) {
          setError(res.error);
        } else {
          setChanged(true);
        }
        // console.log(res);
      });
    setIsSubmitted(false);
  };

  const handleChange = (e) => {
    setReferenceNumber(e.target.value.toUpperCase());
  };
  const handleChange2 = (e) => {
    setVerificationCode(e.target.value.toUpperCase());
  };

  return (
    <>
      {verified && confirmed && changed ? (
        <div className="col-span-12 sm:col-span-12 xl:col-span-12 intro-y">
          <h2 className="intro-y text-6xl font-medium mt-10 text-center">
            Thank you for booking with us.
          </h2>
          <div className="intro-y text-2xl font-medium text-center leading-relaxed">
            <div className="mb-4">
              We've got your booking appointment, we will send you an email
              confirming that you <strong>rescheduled</strong> an appointment.
              Kindly check your inbox.
            </div>
            <Link
              to="/"
              className="button w-24 mr-0 mb-0  bg-theme-1 text-white"
              style={{ width: 150 }}
            >
              Back to Home
            </Link>
          </div>
        </div>
      ) : null}
      {verified && confirmed && !changed ? (
        <>
          <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y">
            <div className="box p-5">
              <Calendar
                localizer={localizer}
                events={events}
                defaultView={"week"}
                defaultDate={events.length === 0 ? today : events[0].start}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 600 }}
                onSelectEvent={(e) => handleSelectSlot(e)}
              />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y">
            {error !== null ? (
              <div className="rounded-md flex items-center px-5 py-4 mb-2 bg-theme-6 text-white">
                {error}
                <FeatherIcon
                  icon="x"
                  className="w-4 h-4 ml-auto"
                  onClick={handleCloseErrorMessage}
                />
              </div>
            ) : null}
            <div className="box p-5">
              <div className="p-5" id="basic-datepicker">
                <form
                  className="validate-form"
                  onSubmit={(e) => handleSubmit3(e)}
                >
                  <div className="mt-3">
                    <label className="flex flex-col sm:flex-row">
                      Time Slot
                    </label>
                    <input
                      type="text"
                      className="input w-full border mt-2"
                      disabled={isSubmitted}
                      required
                      readonly
                      value={selectedSlot}
                      onChange={() => console.log()}
                    />
                  </div>
                  {!isChecked ? (
                    <button
                      disabled
                      className="button bg-gray-200 text-gray-600 mt-5"
                    >
                      Confirm Schedule
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="button bg-theme-1 text-white mt-5"
                    >
                      {isSubmitted ? "Submitting..." : "Confirm Schedule"}
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div className="col-span-12 sm:col-span-12 xl:col-span-12 intro-y">
        {verified && !confirmed ? (
          <div>
            <h2 className="intro-y text-2xl font-medium mt-0 text-center">
              Enter the verification code that was sent to your phone.
            </h2>
            <form className="validate-form" onSubmit={(e) => handleSubmit2(e)}>
              <div className="mt-0 text-center">
                <input
                  type="text"
                  className="input w-full border mt-2 mr-2"
                  minLength={8}
                  maxLength={16}
                  style={{ width: 400 }}
                  disabled={isSubmitted || isResend}
                  required
                  value={verificationCode}
                  onChange={(e) => handleChange2(e)}
                />
                <button
                  disabled={isSubmitted || isResend}
                  type="submit"
                  className="button bg-theme-1 text-white mt-5 mr-2"
                >
                  {isSubmitted ? "Verifying..." : "Verify"}
                </button>
                <button
                  onClick={handleResend}
                  disabled={isSubmitted || isResend}
                  type="button"
                  className="button bg-theme-7 text-white mt-5 mr-2"
                >
                  {isResend ? "Resending..." : "Resend Code"}
                </button>
                {error ? (
                  <div className="text-theme-6 mt-2">{error}</div>
                ) : null}
                {success ? (
                  <div className="text-theme-9 mt-2">{success}</div>
                ) : null}
              </div>
            </form>
          </div>
        ) : null}
        {!verified && !confirmed ? (
          <div>
            <h2 className="intro-y text-2xl font-medium mt-0 text-center">
              Enter reference number of your appointment.
            </h2>
            <form className="validate-form" onSubmit={(e) => handleSubmit(e)}>
              <div className="mt-0 text-center">
                <input
                  type="text"
                  className="input w-full border mt-2 mr-2"
                  minLength={8}
                  maxLength={16}
                  style={{ width: 400 }}
                  disabled={isSubmitted || isResend}
                  required
                  value={referenceNumber}
                  onChange={(e) => handleChange(e)}
                />
                <button
                  disabled={isSubmitted || isResend}
                  type="submit"
                  className="button bg-theme-1 text-white mt-5 mr-2"
                >
                  {isSubmitted ? "Verifying..." : "Verify"}
                </button>
                {error ? (
                  <div className="text-theme-6 mt-2">{error}</div>
                ) : null}
              </div>
            </form>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default Reschedule;
