import { useEffect, useState } from "react";
import moment from "moment";
import {Api} from "./api";

const useGetTimeSlots = () => {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    fetch(`${Api.host}/appointments-api/timeslots`)
      .then((res) => {
        if (!res.ok) {
          throw Error("could not get data from that resource");
        }
        return res.json();
      })
      .then((data) => {
        let slot = [];
        if (data.length === 0) {
          setEvents([]);
        } else {
          data.map((d) => {
            d.start = moment(d.start.toString()).toDate();
            d.end = moment(d.end.toString()).toDate();
            slot.push(d);
            setEvents(slot);
            return null;
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return events;
};

export default useGetTimeSlots;
